import { createRouter, createWebHistory } from 'vue-router'




import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "ViewLayout",
    component: () => import("../views/ViewLayout.vue"),
    async beforeEnter(to, from, next) {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "ViewLogin" });
      }
    },
    children: [
      {
        path: "users",
        name: "ViewUsers",
        component: () => import("../views/Users/ViewUsers.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "user/:id",
        name: "ViewUser",
        component: () => import("../views/Users/ViewUser.vue"),
        meta: { parent: ["ViewUsers"] },
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "tags",
        name: "ViewTags",
        component: () => import("../views/Tags/ViewTags.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "groups",
        name: "ViewGroups",
        component: () => import("../views/Groups/ViewGroups.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "group/:id",
        name: "ViewGroup",
        meta: { parent: ["ViewGroups"] },
        component: () => import("../views/Groups/ViewGroup.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "tests",
        name: "ViewTests",
        component: () => import("../views/Tests/ViewTests.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "test/:id",
        name: "ViewTest",
        meta: { parent: ["ViewTests"] },
        component: () => import("../views/Tests/ViewTest.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "courses",
        name: "ViewCourses",
        component: () => import("../views/Courses/ViewCourses.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "course/:id",
        name: "ViewCourse",
        meta: { parent: ["ViewCourses"] },
        component: () => import("../views/Courses/ViewCourse.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "topics",
        name: "ViewTopics",
        component: () => import("../views/Topics/ViewTopics.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "news",
        name: "ViewNews",
        component: () => import("../views/News/ViewNews.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "news/:id",
        name: "ViewNew",
        meta: { parent: ["ViewNews"] },
        component: () => import("../views/News/ViewNew.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "lesson/:id",
        name: "ViewLesson",
        meta: { parent: ["ViewCourses"] },
        component: () => import("../views/Lessons/ViewLesson.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },

      {
        path: "documents",
        name: "documents",
        component: () => import("../views/ViewDocuments.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },

      {
        path: "settings",
        name: "settingMobile",
        component: () => import("../views/ViewSettingMobile.vue"),
        async beforeEnter(to, from, next) {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "banners",
        name: "banners",
        component: () => import("../views/Banners/ViewBanners.vue"),
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
      {
        path: "moderation",
        name: "moderation",
        component: () => import("../views/ViewModerationUsers.vue"),
        beforeEnter: async (to, from, next) => {
          if (await store.dispatch("authorizationAdminPanel")) {
            next();
          } else {
            next({ name: "ViewLogin" });
          }
        },
      },
    ],
  },
  {
    path: "/login",
    name: "ViewLogin",
    component: () => import("../views/ViewLogin.vue"),
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        if (localStorage.getItem("lastPage") !== null) {
          const lastPage = window.localStorage
            .getItem("lastPage")
            .replace("/", "")
            .replace(/"/g, "");
          next({
            path: lastPage,
          });
        } else {
          next({ name: "ViewLayout" })
        }
      } else {
        next();
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    beforeEnter: async (to, from, next) => {
      if (await store.dispatch("authorizationAdminPanel")) {
        next();
      } else {
        next({ name: "ViewLogin" });
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
