<template>
  <v-textarea
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :label="label"
    :rules="checkRules"
    color="#0078FF"
    no-resize
    rows="3"
    variant="outlined"
  ></v-textarea>
</template>
<script>
export default {
  props: {
    label: { type: String, default: "" },
    modelValue: {},
    valid: { type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {},
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || this.$i18n.t('variables.rules.required')];
      }
      return [];
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style scoped lang="scss">
</style>
