<template>
  <v-text-field
    :disabled="disabled"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :label="label"
    :rules="checkRules"
    color="#0078FF"
    variant="outlined"
    :clearable="clearable"
    :error-messages="errorMessages"
    :maxlength="maxLength"
    :placeholder="placeholder"
    :prepend-inner-icon="prependIcon"
    :type="type"
    :min="0"
    :max="max"
    ref="input"
    :hide-details="hideDetails"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    clearable: { type: Boolean, default: false },
    errorMessages: { type: String, default: "" },
    label: { type: String, default: "" },
    modelValue: {},
    valid: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    max: { type: Number },
    maxLength: { type: Number },
    prependIcon: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {},
  computed: {
    checkRules() {
      if (this.valid) {
        if (this.type == "number") {
          if (this.max) {
            return [
              (v) => !!v || this.$i18n.t('variables.rules.required'),
              (v) => (v && v >= 0) ||  this.$i18n.t('comp_text_input.rules.min_value_zero'),
              (v) =>
                (v && v <= this.max) || `${this.$i18n.t('comp_text_input.rules.max_value')} ${this.max}`,
            ];
          }
          return [(v) => !!v || this.$i18n.t('variables.rules.required')];
        } else if (this.type == "phone") {
          return [
            (value) => !!value || this.$i18n.t('variables.rules.required'),
            (value) => value.length === 12 || this.$i18n.t('comp_text_input.rules.phone'),
          ];
        } else if (this.type == "mail") {
          return [
            (value) => !!value || this.$i18n.t('variables.rules.required'),
            (value) =>
              this.validateEmail(value) ||
              this.$i18n.t('comp_text_input.rules.mail'),
          ];
        }
        return [(v) => !!v || this.$i18n.t('variables.rules.required')];
      }
      return [];
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style lang="scss">
</style>
