import axios from "axios";

export default {
  methods: {
    async uploadFilesAttach(apiPath, files, id) {
      const clearLinksFiles = files.filter((fileObj) => fileObj.file);
      if (clearLinksFiles.length) {
        let formDataFiles = new FormData();
        for (const fileObj of clearLinksFiles) {
          formDataFiles.append("files", fileObj.file);
        }
        await axios.post(
          this.$store.getters.apiV7 + `/attach/${apiPath}/${id}`,
          formDataFiles,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        );
      }
    },
    async deleteFileAttach(apiPath, id) {
      await axios.delete(
        this.$store.getters.apiV7 + `/attach/${apiPath}/${id}`,
        {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        }
      );
    },
    createImageURL(val) {
      if (val?.includes("http")) {
        return val;
      }
      return this.$store.getters.baseURL + val;
    },
    validateEmail(mail) {
      const reg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
      return reg.test(mail);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return "";
      return phoneNumber.replace(
        /(\d)(\d{3})(\d{3})(\d{2})(\d{2})/,
        "+$1 ($2) $3 $4-$5"
      );
    },
    declinationOfWords(value, words) {
      value = Math.abs(value) % 100;
      var num = value % 10;
      if (value > 10 && value < 20) return words[2];
      if (num > 1 && num < 5) return words[1];
      if (num == 1) return words[0];
      return words[2];
    },
    getInformationImage(image) {
      return new Promise((resolve, reject) => {
        try {
          const img = new Image();
          img.src = URL.createObjectURL(image);
          img.onload = () => {
            const newObj = {
              width: img.width,
              height: img.height,
            };
            resolve(newObj);
          };
        } catch (e) {
          reject(e);
        }
      });
    },
    wordTruncation(word, from, to) {
      if (word && to && word.length > to) {
        word = word.slice(from, to);
        word += "...";
      }
      return word;
    },

    async involvePagination(paginationSupply, store) {
      const pagination = paginationSupply.paginationParam;
      let items = paginationSupply.itemsParam;
      if (pagination.hasNextPage) {
        try {
          const response = await fetch(
            `${store.apiV7}/${paginationSupply.api}?take=${pagination.take}&page=${pagination.page}`,
            {
              method: "GET",
              headers: {
                authorization: store.adminPanelHeaderAuth,
              },
            }
          );
          const data = await response.json();
          if (paginationSupply.isScroll) {
            items = [...items, ...data.data];
            pagination.page = data.page;
          } else {
            items = data.data;
          }
          if (data.totalPage === data.page) {
            pagination.hasNextPage = false;
          }
          return { itemsNew: items, paginationNew: pagination };
        } catch (error) {
          console.error(error);
        }
      }
      return {
        itemsNew: paginationSupply.itemsParam,
        paginationNew: pagination,
      };
    },

    createDebounce() {
      let timeout = null;
      return (fnc, delayMs) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 500);
      };
    },
    async takeFirstFrameVideo(localVideoLink) {
      return new Promise((resolve, reject) => {
        let video = document.createElement("video");
        let thecanvas = document.createElement("canvas");
        const context = thecanvas.getContext("2d");
        video.preload = "auto";
        video.src = localVideoLink;
    
        // Ждем, пока видео загрузится
        video.addEventListener("loadedmetadata", function () {
          thecanvas.width = video.videoWidth;
          thecanvas.height = video.videoHeight;
        });
    
        video.addEventListener("canplay", async function () {
          // Воспроизводим видео на короткое время
          video.currentTime = 0; // Убедитесь, что мы на первом кадре
          video.play();
    
          // Ждем небольшую задержку, чтобы дать время для воспроизведения 
          setTimeout(() => {
            // Рисуем первое изображение
            context.drawImage(video, 0, 0, thecanvas.width, thecanvas.height);
    
            // Получаем dataURL и создаем блоб
            const dataURL = thecanvas.toDataURL();
            fetch(dataURL)
              .then(res => res.blob())
              .then(blob => resolve(blob))
              .catch(error => reject(error))
              .finally(() => {
                video.pause(); // Останавливаем видео после захвата кадра
                video.src = ""; // Освобождаем ресурсы
              });
          }, 100); // Задержка в 100 мс, можно настроить по необходимости (для сафари)
        });
    
        video.addEventListener("error", function (e) {
          reject(e);
        });
      });
    }
    
  },
};
