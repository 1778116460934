<template>
  <div class="video-block">
    <v-file-input
      :model-value="typeof modelValue == 'object' ? modelValue : newValue"
      @update:modelValue="change($event)"
      :label="label"
      variant="outlined"
      :rules="checkRules"
      color="#0078FF"
      accept="video/*"
      :prepend-icon="null"
    ></v-file-input>
    <div class="video-block-img">
      <v-icon :color="colorIcon" v-if="!src">
        {{ mdiIcon }}
      </v-icon>
      <video
        @click="dialog = true"
        v-if="src"
        class="block-img-file"
        :src="src"
      ></video>
    </div>
    <DialogVideo :src="src" v-model="dialog" />
  </div>
</template>
<script>
import DialogVideo from "@/components/main/dialog/DialogVideo";
export default {
  components: {
    DialogVideo,
  },
  props: {
    label: { type: String, default: "" },
    valid: { type: Boolean, default: false },
    mdiIcon: { type: String, default: "mdi-youtube" },
    colorIcon: { type: String, default: "rgb(0,0,0, 0.54)" },
    modelValue: {},
  },
  data: () => ({
    newValue: null,
    src: "",
    dialog: false,
  }),
  methods: {
    change(e) {
      if (e) {
        this.src = URL.createObjectURL(e);
        this.$emit("update:modelValue", e);
      } else {
        this.src = "";
        this.$emit("update:modelValue", null);
      }
    },
  },
  created() {
    if (this.modelValue) {
      if (typeof this.modelValue == "string") {
        this.src = this.modelValue;
        const video = new Object();
        video.src = this.src;
        video.name = this.$i18n.t('variables.text.downloaded_file');
        this.newValue = video;
      } else {
        this.src = URL.createObjectURL(this.modelValue);
      }
    }
  },
  computed: {
    checkRules() {
      // (value && value.size < 2148000000) || "Размер видеоролика больше 2 Гб",
      if (this.valid) {
        return [(value) => !!value || this.$i18n.t('variables.rules.required')];
      }
      return [];
    },
  },
  mounted() {},

  watch: {
    modelValue() {
      if (this.modelValue) {
        if (typeof this.modelValue == "string") {
          this.src = this.modelValue;
          const video = new Object();
          video.src = this.src;
          video.name = this.$i18n.t('variables.text.downloaded_file');
          this.newValue = video;
        } else {
          this.src = URL.createObjectURL(this.modelValue);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.video-block {
  display: flex;
  .video-block-img {
    margin-left: 10px;
    background: white;
    width: 55px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    .block-img-file {
      width: 55px;
      height: 45px;
      object-fit: cover;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
</style>
