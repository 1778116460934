<template>
  <v-autocomplete
    :items="items"
    :model-value="modelValue || null"
    @update:modelValue="(value) => { $emit('update:modelValue', value); $emit('change') }"
    :label="label"
    v-model:menu="menuOpen"
    :rules="checkRules"
    color="var(--app-blue)"
    :item-title="itemTitle"
    :item-value="itemValue"
    :clearable="clearable"
    variant="outlined"
    :multiple="multiple"
    :chips="multiple"
    closable-chips
    :disabled="disabled ? (modelValue ? true : false) : false"
    :hide-details="hideDetails"
    @focus="openList"
    transition="slide-y-transition"
  >
  </v-autocomplete>
</template>
<script>

export default {
  props: {
    label: { type: String, default: "" },
    itemTitle: { type: String, default: "name" },
    itemValue: { type: String, default: "id" },
    items: {
      type: Array || Object,
      default() {
        return [];
      },
    },
    modelValue: {},
    valid: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    changeText: { type: Boolean, default: false },
    clearable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    smallChips: { type: Boolean, default: false },
  },
  data: () => ({
    menuOpen: false,
  }),
  methods: {
    openList() {
       this.menuOpen = true;
    }
  },
  computed: {
    checkRules() {
      if (this.valid) {
        return [(value) => !!value || this.$i18n.t('variables.rules.required')];
      }
      return [];
    },
  },
  mounted() {},
  created() {},
  watch: {},
};
</script>
<style >
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 1) !important;
}
</style>
